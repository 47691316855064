import { ChangeEvent, KeyboardEvent, useEffect, useRef, useState } from 'react'
import axios from 'axios'
import tw from 'twin.macro'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { useContainerDimensions } from '../utils/useContainerDimension'
import { Search as SearchIcon } from 'react-feather'
import getPathType from '../utils/getPathType'

const Container = tw.div`
    w-full
`

const Input = tw.input`
    py-2 
    px-3
    focus:outline-none 
    w-full
    bg-white
    bg-opacity-0
`

const Dropdown = tw.div`
    absolute
    z-50 
    backdrop-filter 
    backdrop-blur-lg
    bg-white 
    bg-opacity-95
    border 
    border-gray-300 
    mt-1
    overflow-hidden 
    overflow-y-scroll 
    rounded 
    shadow-md
    max-h-72  
`

const Result = tw.li`
    px-3 
    py-2 
    cursor-pointer 
    hover:bg-gray-300
    hover:bg-opacity-80
`

const SearchBtn = tw.a`
    p-2
    focus:outline-none 
    focus:ring
    cursor-pointer
    active:bg-gray-100
    active:text-yellow-500
`

const InputContainer = tw.div`
    flex 
    items-center 
    justify-items-center 
    border 
    border-gray-300 
    rounded
    bg-white
    bg-opacity-60
`

const Autocomplete = () => {
  const router = useRouter()

  const [autocompleteResult, setAutocompleteResult] = useState<string[]>([])
  const [searchType, setSearchType] = useState<string>('')
  const [isFocused, setIsFocused] = useState<boolean>(false)
  const [value, setValue] = useState<string>('')
  const containerRef = useRef(null)
  const { width } = useContainerDimensions(containerRef)

  useEffect(() => {
    if (router.query.query) {
      setValue(router.query.query as string)
    }
    switch (getPathType(router.route)) {
      case 'general':
        setSearchType('search')
        break
      case 'images':
        setSearchType('images')
        break
      case 'news':
        setSearchType('news')
        break
      case 'videos':
        setSearchType('videos')
        break
      default:
        setSearchType('search')
    }
  }, [router])

  const handleAutocomplete = async (event: ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value)
    try {
      const resp = await axios.get('/api/autocomplete', {
        params: {
          q: event.target.value,
        },
      })

      setAutocompleteResult(resp.data[1])
    } catch (e) {
      return
    }
  }

  const handleKeydown = async (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      if (value.trim().length > 0) {
        await router.push(`/${searchType}/${value}`)
      }
    }
  }

  const handleUnfocused = () => {
    setTimeout(() => setIsFocused(false), 150)
  }

  return (
    <Container ref={containerRef}>
      <InputContainer>
        <Input
          type="text"
          onChange={handleAutocomplete}
          onFocus={() => setIsFocused(true)}
          onBlur={handleUnfocused}
          onKeyDown={handleKeydown}
          value={value}
        />
        <Link href={`/search/${value}`} passHref>
          <SearchBtn>
            <SearchIcon className={'h-6 w-6'} />
          </SearchBtn>
        </Link>
      </InputContainer>
      {isFocused && autocompleteResult.length > 0 && (
        <Dropdown style={{ width: width }}>
          <ul>
            {autocompleteResult.map((value, index) => {
              return (
                <Link href={`/${searchType}/${value}`} passHref key={index}>
                  <Result>{value}</Result>
                </Link>
              )
            })}
          </ul>
        </Dropdown>
      )}
    </Container>
  )
}

export default Autocomplete
