import Autocomplete from './autocomplete'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'
import getPathType from '../utils/getPathType'
import tw, { styled } from 'twin.macro'

import AppIcon from '../public/appicon.png'

const Nav = tw.nav`
      backdrop-filter 
      backdrop-blur-3xl
      border-b
      fixed
      w-screen
      bg-white
      bg-opacity-80
      z-20
      shadow-md
`

const TopContainer = tw.div`
      grid 
      grid-cols-4 
      justify-start 
      max-w-7xl 
      mx-auto 
      px-2 
      sm:px-6 
      lg:px-8 
      mt-2
`

const LogoContainer = styled.div`
  ${({ autocomplete }: { autocomplete: boolean }) =>
    autocomplete ? tw`sm:col-span-1` : ''}

  ${tw`flex-shrink-0
      flex 
      items-center 
      mr-2 
      col-span-4`}
`

const Logo = styled.div`
  ${({ autocomplete }: { autocomplete: boolean }) =>
    autocomplete ? tw`sm:mx-0 sm:mr-auto` : ''}

  ${tw`block 
      h-8 
      w-auto
      mx-auto  
      my-2 
      cursor-pointer`}
`

const TabList = tw.div`
      max-w-7xl 
      mx-auto 
      px-2 
      sm:px-6 
      lg:px-8 
      flex 
      overflow-x-auto 
`

const Tab = styled.a`
  ${({ active }: { active: boolean }) =>
    active ? tw`border-b-2 border-yellow-500 text-yellow-500` : tw`text-black`}

  ${tw`px-3 py-2 text-sm font-medium active:bg-gray-100 active:text-yellow-500 rounded-t`}
`

const AutocompleteContainer = tw.div`
      justify-self-center
      w-full 
      col-span-4 
      sm:col-span-2
`

const Navbar = () => {
  const router = useRouter()
  const [currentTab, setCurrentTab] = useState<string>('general')
  const [showAutocomplete, setShowAutocomplete] = useState<boolean>(false)

  useEffect(() => {
    setCurrentTab(getPathType(router.route))
    setShowAutocomplete(
      !(
        router.route.split('/')[2] === undefined ||
        router.route.split('/')[2]?.length === 0
      )
    )
  }, [router])

  const TabLink = (type: string) => {
    return (router.query.query as string)
      ? `/${type}/${router.query.query as string}`
      : `/${type}`
  }

  return (
    <Nav>
      <TopContainer>
        <LogoContainer autocomplete={showAutocomplete}>
          <Link href={'/'} passHref>
            <Logo autocomplete={showAutocomplete}>
              <img src={AppIcon.src} alt="Meerkt" width={32} height={32} />
            </Logo>
          </Link>
        </LogoContainer>
        {showAutocomplete && (
          <AutocompleteContainer>
            <Autocomplete />
          </AutocompleteContainer>
        )}
      </TopContainer>
      <TabList>
        <Link href={TabLink('search')} passHref>
          <Tab active={currentTab === 'general'}>General</Tab>
        </Link>
        <Link href={TabLink('images')} passHref>
          <Tab active={currentTab === 'images'}>Images</Tab>
        </Link>
        <Link href={TabLink('videos')} passHref>
          <Tab active={currentTab === 'videos'}>Videos</Tab>
        </Link>
        <Link href={TabLink('news')} passHref>
          <Tab active={currentTab === 'news'}>News</Tab>
        </Link>
      </TabList>
    </Nav>
  )
}

export default Navbar
